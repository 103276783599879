<template>
    <div class="content-recommendations">
        <div class="recommendations-tabs">
            <ul>
                <li v-for="recommendationCategory in filteredRecommendationsCategories" :key="recommendationCategory.name" :class="{ 'is-active' : recommendationCategory.name == selectedRecommendation }">
                    <a href="javascript:void(0)" @click="selectedRecommendation = recommendationCategory.name">{{ recommendationCategory.title }}</a>
                </li>
            </ul>
        </div>
        <recommended-content-card
            v-for="memo in selectedRecommendationData.recommendations"
            :key="memo.id"
            :img="memo.files.length ? memo.files[0].url : ''"
            :img-proportions="memo.files.length ? memo.files[0].attributes.orientation : ''"
            :url="`/${memo.writer.displayname}/${memo.slug}-${memo.id}`"
            :title="memo.title"
            :creator-displayname="memo.writer.displayname"
        />
    </div>
</template>

<script>
export default {
    name: "ContentRecommendations",
    components: {
        recommendedContentCard: () => import(/* webpackChunkName: "recommended-content-card" */ "@c/molecules/recommended-content-card")
    },
    props: {
        memoId: {
            type: Number,
            required: true
        },
        creatorDisplayname: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            selectedRecommendation: "topics",
            recommendationsCategories: [
                {
                    name: "creator",
                    title: "More from Creator",
                    recommendations: []
                },
                {
                    name: "topics",
                    title: "More from these Topics",
                    recommendations: []
                },
                {
                    name: "trending",
                    title: "Trending",
                    recommendations: []
                }
            ]
        }
    },
    computed: {
        filteredRecommendationsCategories() {
            return this.recommendationsCategories.filter(recommendationCategory => recommendationCategory.recommendations.length)
        },
        selectedRecommendationData() {
            return this.filteredRecommendationsCategories.find(recommendationCategory => recommendationCategory.name === this.selectedRecommendation) || {};
        }
    },
    watch: {
        selectedRecommendationData(newVal) {
            if (!newVal.name) {
                this.selectedRecommendation = this.filteredRecommendationsCategories[0].name;
            }
        }
    },
    created() {
        this.setCreatorTitle();
        this.getContentRecommendations();
    },
    methods: {
        setCreatorTitle() {
            const creatorCategoryIndex = this.recommendationsCategories.findIndex(category => category.name == "creator");
            this.recommendationsCategories[creatorCategoryIndex].title = `More from ${this.creatorDisplayname}`;
        },
        getContentRecommendations() {
            this.recommendationsCategories.forEach((category, index) => {
                axiosV2.get(`/memos/${this.memoId}/recommendations?type=${category.name}&limit=3`).then(({ data }) => {
                    this.recommendationsCategories[index].recommendations = data;
                }).catch(error => {
                    console.log(error)
                });
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content-recommendations {
    .recommendations-tabs {
        margin-bottom: 20px;

        ul {
            padding-left: 0;
            list-style: none;
            margin-bottom: 0;
            display: flex;

            li {
                margin-left: 20px;
                font-size: 16px;

                a {
                    display: block;
                    color: rgba(235, 235, 245, 0.3);
                    text-decoration: none;
                }

                &.is-active {
                    a {
                        color: white;
                    }
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
    .recommendations-tabs {
        /deep/ .nav-tabs {
            border: 0;
            display: flex;
            margin-bottom: 20px;

            .tab {
                margin-left: 20px;

                &:first-child {
                    margin-left: 0;
                }

                .tabs__link {
                    padding: 0;
                    border: 0;
                    color: rgba(235, 235, 245, 0.3);
                    font-size: 16px;

                    span {
                        padding-top: 0;
                    }

                    &.active_tab {
                        background-color: transparent;
                        color: white;
                    }

                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    .recommended-content-card {
        margin-bottom: 15px;
    }
}
</style>
